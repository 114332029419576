<template>
  <b-overlay :show="loadingOverlay">
    <b-form v-if="product" @submit.prevent>
      <b-modal
          id="modal-edit"
          centered
          ok-title="بروزرسانی قیمت"
          ok-only
          @ok.preventDefault()="UpdatePrice"
      >
        <b-row v-if="SelectedPrice!==null">
          <div class="col-md-12">
            <small>قیمت جدید</small>
            <input type="number" class="form-control" v-if="SelectedPrice" v-model="SelectedPrice.priceValue">
          </div>
          <div class="col-md-12 my-2">
            <small>تعداد جدید</small>
            <input type="number" class="form-control" v-if="SelectedPrice" v-model="SelectedPrice.quantity">
          </div>
        </b-row>


      </b-modal>
      <b-card>
        <b-row>

          <b-col md="4" cols="12">
            <b-form-group
                label="نام محصول"
                label-for="mc-first-name"
            >
              <b-form-input
                  v-model="product.title"
                  id="mc-first-name"
                  placeholder="نام محصول"
              />
            </b-form-group>
          </b-col>
          <b-col  v-if="brands" md="4" cols="12">
            <b-form-group
                label="برند محصول"
                label-for="mc-city3"
            >
              <v-select
                  v-model="product.brandId"
                  :options="brands"
                  label="title"
                  :reduce="title => title.brandId"
                  :clearable="false"
              />
            </b-form-group>
          </b-col>
          <!--        <b-col cols="12">-->
          <!--          <b-row>-->
          <!--            <b-col md="12" cols="12">-->
          <!--              <label for="">توضیحات کوتاه محصول</label>-->
          <!--              <textarea class="form-control my-25"  v-model="product.summary" placeholder="توضیحات کوتاه محصول" maxlength="300" ></textarea>-->
          <!--              <span class="" :class="{'text-danger':product.summary.length>300}">تعداد کاراکترهای مجاز <small>{{product.summary.length}}</small><small class="text-secondary">/</small><small class="text-secondary">300</small></span>-->

          <!--            </b-col>-->
          <!--          </b-row>-->
          <!--        </b-col>-->
          <!--      <b-col md="12">-->
          <!--        <b-form-group-->
          <!--            label="دسته بندی"-->
          <!--            label-for="mc-country"-->
          <!--        >-->
          <!--          <v-select-->
          <!--              v-model="product.categoryId"-->
          <!--              :options="Categories"-->
          <!--              label="name"-->
          <!--              :reduce="name => name.productCategoryId"-->
          <!--              class="per-page-selector bg-white d-inline-block w-100"-->
          <!--          />-->
          <!--        </b-form-group>-->
          <!--      </b-col>-->


          <b-col cols="12 my-25">
            <label>جزئیات محصول : </label>
            <b-row>
              <b-col md="3" cols="12">
                <label>نام</label>
                <input v-model="ProductProperty.ProductPropertyName" type="text" class="form-control"
                       placeholder="مثال جنس">
              </b-col>
              <b-col md="3" cols="12">
                <label>مقدار</label>
                <input v-model="ProductProperty.ProductPropertyValue" type="text" class="form-control"
                       placeholder="مثال کتان">
              </b-col>
              <b-col class="my-2" md="2">
                <b-button @click="addNewProperty" variant="primary">افزودن</b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12" class="mb-2">
            <div class="d-flex flex-wrap  align-items-center">
              <b-badge class="mx-25" v-for="item in product.properties " :key="item.propertyId" variant="primary">
                <feather-icon
                    @click="deleteProperty(item.propertyId)"
                    icon="TrashIcon"
                    class="  text-danger cursor-pointer"
                />
                <span class="mx-1">{{ item.name }}:{{ item.value }}</span>
              </b-badge>

            </div>
          </b-col>
          <b-col cols="12" md="4">
            <b-form-group
                label="تاریخ و زمان انتشار"
                label-for=""
            >
              <validation-provider
                  #default="{ errors }"
                  rules="required"
              >
                <input
                    :id="`start${1}`"
                    :state="errors.length > 0 ? false:null"
                    :value="createJalaliDate(product.publishDate)"
                    class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                    placeholder="تاریخ و زمان انتشار"
                    type="text"
                />
              </validation-provider>
              <custom-date-picker
                  v-model="product.publishDate"
                  :element="`start${1}`"
                  :min="new Date(Date.now())"
                  auto-submit
                  class=""
                  color="#5c6bc0"
                  display-format="jYYYY/jMM/jDD HH:mm"
                  format="YYYY-MM-DD HH:mm"
                  input-format="YYYY-MM-DD HH:mm"
                  type="datetime"
              ></custom-date-picker>
            </b-form-group>
          </b-col>
          <b-col v-if="product && product.photos && product.photos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in product.photos" :key="idx" class="position-relative mx-2 bg-white rounded">
                <feather-icon @click="RemovePic(item,idx)" icon="TrashIcon" size="20"
                              class="text-danger position-absolute top-0 cursor-pointer"/>
                <img :src=" baseUrl+item.base64"
                     style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
              </div>

            </div>

          </b-col>
          <b-col cols="12" >
            <h2 class="mb-2">برچسب ها</h2>

            <b-badge
                v-if="product.tags.length>0"
                v-for="(tag,idx) in product.tags"
                :key="idx"
                variant="warning"
                class="p-1 cursor-pointer m-1"
            >
              <feather-icon
                  @click="deleteTag(tag)"
                  icon="XIcon"
                  class="text-danger"

              />
              <span class="ml-1 pt-25" >
          {{ tag.value }}
        </span>
            </b-badge>

            <b-row class="mt-2">
              <b-col md="6" class="">
                <b-form-group
                    class="mb-2"
                    label="برچسب جدید"
                    label-for="tagIds"
                >
                  <b-form-input
                      id="tagIds"
                      v-model="newTagValue"
                      placeholder="برچسب جدید"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-button
                    variant="warning"
                    class="col-12 col-md-2 mt-2"
                    :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                    @click="addTag"
                >
                  افزودن
                </b-button>
              </b-col>
            </b-row>
          </b-col>

          <b-col cols="12">
            <b-form-group
                label="پیوست عکس"
                label-for="mc-country"
            >
              <b-form-file

                  accept=".jpg, .png, .gif"
                  placeholder="انتخاب عکس"
                  @input="makeBase64Pic"
                  ref="refInputEl"
                  drop-placeholder="Drop file here..."
                  multiple
              />
            </b-form-group>

          </b-col>

          <b-col v-if="ProductPhotos.length>0" class="my-3" cols="12">
            <div class="d-flex align-items-center justify-content-start  flex-wrap">
              <div v-for="(item,idx) in ProductPhotos.filter(e=> e.priority!==0)" :key="idx"
                   class="position-relative mx-2 bg-white rounded">
                <feather-icon @click="ProductPhotos.splice(idx,1)" icon="TrashIcon" size="20"
                              class="text-danger position-absolute top-0 cursor-pointer"/>
                <img :src="item.blob" style="width: 10rem;height: 10rem;object-fit: contain" class="rounded" alt="">
              </div>

            </div>

          </b-col>
          <b-col cols="12">
            <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="product.prices"
                responsive
                :fields="myTableColumns"
                primary-key="id"
                show-empty
                bordered
                striped
                empty-text="موردی موجود نیست!"
            >

              <!-- Column: delete -->

              <template #cell(priceValue)="data">

                <small>{{ Intl.NumberFormat('fa-IR').format(data.item.priceValue) }} <small
                    class="Toman">تومان</small></small>
              </template>
              <template #cell(quantity)="data">

                <small>{{ data.item.quantity }} </small>
              </template>
              <template #cell(optionValues)="data">

                <small v-for="el in data.item.optionValues">
                            <span v-if="!el.value.includes('#')">
                                {{ el.value }}
                            </span>
                  <div v-else class="d-flex">
                    <div class="p-2 rounded-circle" :style="`background-color:${el.value}`"></div>
                  </div>

                </small>
              </template>
              <template #cell(Edit)="data">

                <div class="cursor-pointer d-flex flex-row"
                     v-b-modal.modal-edit
                     @click="SetSelectedPrice(data.item)"
                >
                  <feather-icon class="text-primary" icon="EditIcon" size="20"/>
                </div>

              </template>

            </b-table>
          </b-col>
          <b-col cols="12">
            <addPrice v-if="product.variablePrices" :Variable="product.variablePrices" @SendPrice="CallPrices"></addPrice>
          </b-col>
          <b-col
              cols="12"
          >
            <b-form-group
                label="توضیحات"
                label-for="summary"
            >
                <ckEditorMain ref="editorContent" @getEditorContent="setEditorContent" :defaultContent="product.description"></ckEditorMain>
            </b-form-group>
          </b-col>
          <!-- submit and reset -->

        </b-row>

      </b-card>
      <b-card class="blog-edit-wrapper">
        <h2>سئو</h2>

        <b-form v-if="product" class="mt-2">
          <b-row>
            <b-col md="6" class="">
              <b-form-group
                  label="عنوان"
                  label-for="blog-edit-title"
                  class=""
              >
                <b-form-input
                    id="blog-edit-seoTitle"
                    v-model="product.seoTitle"
                />
              </b-form-group>
              <span v-if="product.seoTitle"
                    :class="{'text-danger':product.seoTitle.length>60}">تعداد کاراکترهای مجاز <small>{{
                  product.seoTitle.length
                }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">60</small></span>
            </b-col>
            <b-col md="6">
              <b-row>
                <b-col cols="10">
                  <b-form-group
                      label="آدرس"
                      label-for="blog-edit-seourl"
                      class=""
                  >
                    <b-form-input
                        id="blog-edit-seourl"
                        v-model="allCharactersToDash"
                        v-on:focus="$event.target.select()"
                        ref="myInput"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="2">
                  <b-button
                      variant="warning"
                      class="mt-2 ml-2"
                      @click="copyText"
                  >
                    کپی
                  </b-button>
                </b-col>
              </b-row>
            </b-col>
            <!-- Field:  description -->
            <b-col cols="12" class="mt-2">
              <b-form-group
                  label="توضیحات"
                  label-for="blog-content"
                  class="mb-2"
              >
                <b-form-textarea
                    v-model="product.seoDescription"
                ></b-form-textarea>
              </b-form-group>
              <span class=""
                    :class="{'text-danger':product.seoDescription.length>160}">تعداد کاراکترهای مجاز <small>{{
                  product.seoDescription.length
                }}</small><small
                  class="text-secondary">/</small><small class="text-secondary">160</small></span>
            </b-col>

            <b-card class="mt-1 w-100">

              <h2>نحوه نمایش در گوگل</h2>

              <div class="col-md-12 my-3">
                <!--- serp Desktop --->
                <div class="serp-preview d-lg-flex p-3 d-none flex-column align-items-end">
                  <div class="serp-title">
                    <h4 style="color: rgb(26, 13, 171)!important">
                      {{ product.seoTitle }}
                    </h4>
                  </div>
                  <div class="serp-url">
                    <h5 class="d-flex align-items-center flex-wrap" style="color: rgb(0, 102, 33)!important">
                      <span style="overflow-wrap: anywhere">{{ product.seourl.replaceAll(' ', '-') }}/</span>
                      <span>
                             {{ 'https://enzamode.ir/plaza/' }}
                        <span class="serp-arrow"></span>
                      </span>

                    </h5>
                  </div>
                  <div class="serp-description">
                    <h6 style="overflow-wrap: anywhere">
                      {{ product.seoDescription }}
                    </h6>
                  </div>
                </div>
                <!--- serp Mobile --->
                <div class="serp-preview d-lg-none  p-3 d-flex flex-column align-items-end">
                  <div class="serp-title">
                    <h6>
                      {{ product.seoTitle }}
                    </h6>
                  </div>
                  <div class="serp-url d-flex align-items-center flex-wrap">

                    <small>{{ product.seourl.replaceAll(' ', '-') }}/</small>
                    <small>
                      {{ 'https://enzamode.ir/plaza/' }}
                      <small class="serp-arrow"></small>
                    </small>
                  </div>
                  <div class="serp-description">
                    <small>
                      {{ product.seoDescription }}
                    </small>
                  </div>
                </div>
              </div>
            </b-card>

            <b-col>
              <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="button"
                  variant="primary"
                  class="mr-1"
                  @click="UpdateProduct"
              >
                ثبت نهایی
              </b-button>

            </b-col>
          </b-row>
        </b-form>
      </b-card>
    </b-form>
  </b-overlay>

</template>


<script>
import {
  BRow, BCol,BOverlay,BFormGroup, BFormTextarea,BBadge, BFormInput, BTable, BFormCheckbox, BForm, BButton, BMedia, BCard, BFormFile,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ckEditorMain from "@/views/components/ckEditorMain";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import vSelect from 'vue-select'
import {required} from '@validations'
import addPrice from '@/views/components/addPrice.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import {getUserData} from "@/auth/utils";
import {GetProductByIdForVendor, UpdatePrice, UpdateProduct} from "@/libs/Api/product";
import {CreateProperty, DeleteProperty} from "@/libs/Api/property";
import Helper from "@/libs/Helper";
import {GetAllBrand} from "@/libs/Api/brands";
import {TagCreateRequest, TagDeleteRequest} from "@/libs/Api/Blog";
import {DeleteMediaByPicURL} from "@/libs/Api/portfolio";
export default {
  components: {
    ToastificationContent,
    BBadge,
    BOverlay,
    BTable,
    BRow,
    BCol,
    addPrice,
    ValidationProvider,
    ValidationObserver,
    BFormTextarea,
    ckEditorMain,
    vSelect,
    BMedia,
    BCard,
    BFormInput,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BForm,
    BButton,
  },
  data() {
    return {
      loadingOverlay:false,
      ProductProperty: {
        ProductPropertyName: '',
        ProductPropertyValue: ''
      },
      myTableColumns: [
        {
          key: 'priceId',
          label: 'شناسه'
        },
        {
          key: 'priceValue',
          label: 'قیمت'
        },
        {
          key: 'quantity',
          label: 'تعداد'
        },
        {
          key: 'optionValues',
          label: 'نام ویژگی'
        },
        {
          key: 'Edit',
          label: 'ویرایش'
        },
      ],
      baseUrl: Helper.baseUrl,
      base64ImageSrc: '',
      FinalPriceV: null,
      SinglePRiceV: null,
      SingleQty: null,
      PType: null,
      brands:null,
      ProductPhotos: [],
      productProperties: [],
      newTagValue:'',
      Properties: [],
      variablePrices: false,
      SelectedPrice: null,
      product: null
    }
  },
  async mounted() {
    await this.GetProduct();
    await this.getAllBrands();
  },
  computed:{
    allCharactersToDash: {
      get: function () {
        return this.product.seourl;
      },
      set: function (newValue) {
        // This setter is getting number, replace all commas with empty str
        // Then start to separate numbers with ',' from beginning to prevent
        // from data corruption
        if (newValue) {
          this.product.seourl = newValue
          // Remove all characters that are NOT number
          this.product.seourl = this.product.seourl.replace(/[+*!@#$%^&*()_;:~`»«,×='"|<>/?{}\-/\.]/g, "-");
          this.product.seourl = this.product.seourl.replaceAll(' ', '-')
        } else if (!newValue || this.product.seourl === "") {
          this.product.seourl = null;
        }
      },
    },
  },
  methods: {
    setEditorContent(content){
      this.product.description = content
    },
    async addTag() {
      let _this = this;
      _this.showOverlay = true;
      let data = {
        isDeleted: false,
        tagId: 0,
        value:_this.newTagValue,
        objectId: _this.product.productId,
        type:1,
        createDate:new Date(Date.now())
      }

      let tagCreateRequest = new TagCreateRequest(_this);
      tagCreateRequest.setParams(data);
      await tagCreateRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب اضافه شد.`,
          },
        })
        _this.showOverlay = false;
        _this.GetProduct();
        _this.newTagValue = ''
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    async deleteTag(tag) {
      let _this = this;
      _this.showOverlay = true;

      let data = {
        id : tag.tagId,
      }

      let tagDeleteRequest = new TagDeleteRequest(_this);
      tagDeleteRequest.setParams(data);
      await tagDeleteRequest.fetch(function (content) {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `برچسب حذف شد.`,
          },
        })
        _this.showOverlay = false;
       _this.GetProduct();
      }, function (error) {
        _this.showOverlay = false;
        console.log(error)
      })
    },
    createJalaliDate(param) {
      if (param !== '') {

        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
    async getAllBrands(){
      let _this = this
      let getAllBrand = new GetAllBrand(_this)
      let data = {
        pageNumber: 0,
        search: '',
        count: 0,
      }
      getAllBrand.setParams(data)
      await getAllBrand.fetch(function (content) {
        _this.brands = content.data.requests

      }, function (error) {
        console.log(error)
      })
    },
    copyText() {
      this.$refs.myInput.focus();
      document.execCommand('copy');
      this.$toast({
        component: ToastificationContent,
        position: 'bottom-center',
        props: {
          title: `آدرس محصول کپی شد.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      })
    },
    async UpdatePrice() {
      let _this = this
      let updatePrice = new UpdatePrice(_this)
      let data = {
        priceId: this.SelectedPrice.priceId,
        productId: parseInt(this.SelectedPrice.productId),
        optionValueId: null,
        vendorUserId: getUserData().userId,
        priceValue: this.SelectedPrice.priceValue,
        quantity: parseInt(this.SelectedPrice.quantity),
      }
      updatePrice.setData(data)
      await updatePrice.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `قیمت بروزرسانی شد`,
          },
        })
        _this.GetProduct();
      }, (err) => {
        console.log(err)
      })
    },
    SetSelectedPrice(item) {
      this.SelectedPrice = JSON.parse(JSON.stringify(item))
    },
    async RemovePic(item, idx) {
      let _this = this
      _this.loadingOverlay = true
      let deleteMediaByPicURL = new DeleteMediaByPicURL(_this)
      deleteMediaByPicURL.setParams([item.base64])
        await  deleteMediaByPicURL.fetch((content)=>{
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `عکس حذف شد`,
            },
          })
          _this.GetProduct();
        },(err)=>{
          console.log(err)
        })
      _this.loadingOverlay = false

    },
    async UpdateProduct() {
      let FinalPrice = []
      if(this.product.variablePrices){
        let productIds = {
          productId: parseInt(this.$route.params.id)
        }
        if(this.FinalPriceV!==null){
          this.FinalPriceV.forEach((item)=>{
            let result = {...item,...productIds}
            FinalPrice.push(result)
          })
        }
      }
      this.ProductPhotos.map((item)=>{
        delete item.blob
      })

      this.$refs.editorContent.emitDataToParent()
      let _this = this
      _this.loadingOverlay = true

      let updateProduct = new UpdateProduct(_this)
      let data = {
        productId:parseInt(_this.$route.params.id),
        title: this.product.title,
        summary:this.product.summary,
        createDate:this.product.createDate,
        seourl:this.product.seourl,
        brandId:this.product.brandId,
        country:this.product.country,
        seoTitle:this.product.seoTitle,
        isTolerated:this.product.isTolerated,
        isInStock:this.product.isInStock,
        publishDate:this.product.publishDate,
        tags:this.product.tags,
        seoDescription:this.product.seoDescription,
        variablePrices:this.product.variablePrices,
        description: this.product.description,
        expireDate:this.product.expireDate,
        vendorUserId:this.product.vendorUserId,
        productCategoryId: this.product.productCategoryId,
        prices:FinalPrice,
        photos: this.ProductPhotos,
      }
      updateProduct.setData(data)
      await updateProduct.fetch((content)=>{
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `محصول بروزرسانی شد`,
          },
        })

        _this.$router.push('/pages/products/ProductsList')
      },(err)=>{
        console.log(err)
      })
      _this.loadingOverlay = false

    },
    async addNewProperty() {
      let _this = this
      if (this.ProductProperty.ProductPropertyValue === '' || this.ProductProperty.ProductPropertyName === '') {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: `لطفا یک مقدار وارد کنید`,
          },
        })
      } else {
        let createProperty = new CreateProperty(_this)
        let data = {
          productId: parseInt(this.$route.params.id),
          name: this.ProductProperty.ProductPropertyName,
          value: this.ProductProperty.ProductPropertyValue
        }
        createProperty.setData(data)
        await createProperty.fetch((content) => {
          _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `عملیات موفق`,
              icon: 'CheckIcon',
              variant: 'success',
              text: `ویژگی با موفقیت ساخته شد`,
            },
          })
          this.GetProduct();
          this.ProductProperty.ProductPropertyValue = ''
          this.ProductProperty.ProductPropertyName = ''

        }, (err) => {
          console.log(err)
        })

      }

    },
    async deleteProperty(id) {
      let _this = this
      let deleteProperty = new DeleteProperty(_this)
      let data = {
        id: id
      }
      deleteProperty.setParams(data)
      await deleteProperty.fetch((content) => {
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات ناموفق`,
            icon: 'CheckIcon',
            variant: 'danger',
            text: `لطفا یک مقدار وارد کنید`,
          },
        })
        this.GetProduct();
      }, (err) => {
        console.log(err)
      })


    },
    async GetProduct() {
      let _this = this;
        _this.loadingOverlay = true
      let getProductByIdForVendor = new GetProductByIdForVendor(_this);
      let data = {
        productId: parseInt(this.$route.params.id)
      }
      getProductByIdForVendor.setParams(data);
      await getProductByIdForVendor.fetch(function (content) {
        _this.product = content
      }, function (error) {
        console.log(error)
      })
      _this.loadingOverlay = false

    },
    CallPrices(FinalPriceV, SinglePriceV, SingleQty, PType) {
      this.FinalPriceV = FinalPriceV
      this.SinglePRiceV = SinglePriceV
      this.SingleQty = SingleQty
      this.PType = PType
    },

    async makeBase64Pic() {
      const that = this
      const f = []
      let tmpImage = {
        blob: '',
        base64: '',
        priority: 2
      }
      if (this.$refs.refInputEl.files.length > 4 || this.ProductPhotos.length > 4) {
        this.$toast.error('میتوانید تا 5 عکس را همزمان اضافه کنید')
      } else {
        Array.prototype.forEach.call(this.$refs.refInputEl.files, (element) => {
          if (element.size > 512000) {
            this.$toast.error('اندازه عکس نمیتواند بیشتر از 512 کیلوبایت باشد')
          } else {
            f.push(element)
          }
        })
        f.forEach((element) => {
          const reader = new FileReader()
          reader.onload = (function (theFile) {
            return function () {
              let binaryData = reader.result
              tmpImage.base64 = window.btoa(binaryData)
              tmpImage.blob = URL.createObjectURL(element)
              const clone = {...tmpImage}
              that.ProductPhotos.push(clone)
              tmpImage.blob = ''
              tmpImage.priority = 2
              tmpImage.base64 = ''
            }
          })(f)
          reader.readAsBinaryString(element)
        })

      }
      this.$refs.refInputEl.reset()


    }


  },
  directives: {
    Ripple,
  },
}
</script>

